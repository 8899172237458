@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --font-primary: "Roboto", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);
  --color-root-bkg: #e9ecf1;

  --font-light: 300;

  --toastify-color-error: #d73c23;
  --toastify-color-warning: #e88949;
  --toastify-color-success: #a0c167;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.app-container {
  /* max-width: 1800px; */
  width: 100%;
  margin: 0px auto;
  position: relative;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}